/* reports.css */

.reports-page {
  min-height: 100vh;
  background-color: #f8f9fa;
  font-family: var(--font-proxima-nova);
  width: 100%;
  overflow-x: hidden;
  position: relative;
  overflow: visible !important;
  padding-bottom: 58px;
}

.reports-header {
  position: relative;
  background-image: url('https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80');
  background-size: cover;
  background-position: center;
  height: 260px;
  color: white;
  width: 100%;
  overflow: hidden;
}

.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(41, 118, 120, 0.9),
    rgba(52, 142, 180, 0.9)
  );
  width: 100%;
  height: 100%;
}

.header-content {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  padding: 3rem 1rem;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.header-icon {
  background: url('https://cdn-icons-png.flaticon.com/512/4300/4300059.png')
    no-repeat center;
  background-size: contain;
  width: 48px;
  height: 48px;
  margin-right: 15px;
}

.screen-title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 1rem;
  font-family: var(--font-proxima-nova);
}

.reports-container {
  max-width: 1000px;
  margin: -60px auto 2rem;
  padding: 0 1rem;
  position: relative;
}

.filter-section {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 25px;
  margin: 20px 0;
  width: 100%;
  box-sizing: border-box;
  overflow: visible;
  position: relative;
  z-index: 5;
  overflow: visible !important;
  margin-bottom: 40px;
}

.filter-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  color: #2c3e50;
  font-family: var(--font-proxima-nova);
}

.filter-header i {
  color: #3498db;
}

.filter-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 5;
}

.filter-item {
  position: relative;
  z-index: 5;
}

.filter-item label {
  display: block;
  margin-bottom: 0.5rem;
  color: #2685ce;
  font-weight: 500;
  font-family: var(--font-proxima-nova);
  font-size: 17px;
}

.filter-title {
  color: #2685ce;
  font-weight: 500;
}
.custom-select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #30d5c8;
  border-radius: 6px;
  background: url('/public/profile-update/dropdown-arrow.svg') no-repeat right
    10px center;
  background: #f2fffe;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}

.custom-select:focus {
  border-color: #3498db;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
}

.clear-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  margin-top: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.2s;
}

.clear-button:hover {
  background-color: #c0392b;
}

.report-list {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.report-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #e2e8f0;
  transition: background-color 0.2s;
}

.report-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.report-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.report-info i {
  color: #e74c3c;
  font-size: 1.25rem;
}

.report-info span {
  color: #2d3748;
  font-size: 0.95rem;
  font-family: var(--font-proxima-nova);
}

.report-item button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.2s;
}

.report-item button:hover {
  background-color: #2980b9;
}

@media (max-width: 768px) {
  .reports-header {
    height: 200px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .header-content {
    width: 100%;
    box-sizing: border-box;
    padding: 1.5rem 1rem;
    min-height: 200px;
  }

  .header-content p {
    font-size: 14px;
  }

  .screen-title {
    font-size: 24px;
    margin-bottom: 0.5rem;
  }

  .reports-container {
    margin-top: -40px;
    padding: 0 0.75rem;
    width: 100%;
    box-sizing: border-box;
    overflow: visible;
    position: relative;
    z-index: 1;
  }

  .filter-section {
    padding: 1rem;
    margin: 15px 0;
    width: 100%;
    box-sizing: border-box;
    overflow: visible;
    overflow: visible !important;
  }

  .filter-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    z-index: 3;
  }

  .filter-header {
    margin-bottom: 1rem;
  }

  .filter-title {
    font-size: 16px;
  }

  .filter-item label {
    font-size: 15px;
    margin-bottom: 0.3rem;
  }

  .custom-select,
  .rp-form-select {
    font-size: 16px;
    padding: 8px 30px 8px 8px;
  }

  .clear-button {
    padding: 0.6rem 1.2rem;
    font-size: 14px;
    width: 100%;
    justify-content: center;
  }

  .report-item {
    padding: 0.75rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
  }

  .report-info {
    width: 100%;
    font-size: 14px;
  }

  .report-item button {
    width: 100%;
    justify-content: center;
    padding: 0.5rem;
    font-size: 14px;
  }

  .no-reports {
    font-size: 14px;
    padding: 20px;
  }

  .rp-form-select,
  .custom-select {
    width: 100%;
    position: relative;
    z-index: 1000;
  }

  .filter-item {
    position: relative;
    z-index: 1000;
  }

  select:focus {
    z-index: 1001;
  }

  .rp-form-select,
  .custom-select {
    background-position: calc(100% - 10px) center !important;
  }
}
@media screen and (max-width: 450px) {
  .reports-page {
    padding-top: 58px;
  }
  .filter-section {
    margin-top: 40px;
  }
}

/* Add styles for very small screens */
@media (max-width: 360px) {
  .screen-title {
    font-size: 20px;
  }

  .reports-header {
    height: 180px;
  }

  .filter-section {
    padding: 0.75rem;
  }

  .header-content {
    padding: 1rem 0.75rem;
  }

  .screen-title {
    font-size: 20px;
    word-wrap: break-word;
  }

  /* Adjust select padding for very small screens */
  select.rp-form-select,
  select.custom-select {
    padding: 0.5rem 25px 0.5rem 0.5rem;
  }
}

.rp-form-label {
  display: block;
  color: #2c3e50;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.required-field {
  color: #e74c3c;
  margin-left: 2px;
}

.rp-form-select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #30d5c8;
  border-radius: 6px;
  background: url('/public/profile-update/dropdown-arrow.svg') no-repeat right
    10px center !important;
  background-color: #f2fffe !important;
  background-size: 12px !important;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  padding-right: 30px !important;
}

.dropdown-arrow-rp {
  background: url('/public/profile-update/dropdown-arrow.svg') no-repeat right
    10px center;
  background-color: #f2fffe;
  background-size: 12px;
  padding-right: 30px !important;
}

.rp-form-select:focus {
  border-color: #5656f2;
  box-shadow: 0 0 0 3px rgba(86, 86, 242, 0.2);
  outline: none;
}

/* Add loading states */
.loading-select {
  position: relative;
  opacity: 0.7;
  pointer-events: none;
}

/* Empty state styling */
.no-reports {
  text-align: center;
  padding: 40px;
  color: #666;
  font-size: 1.1em;
}

/* Add animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.report-item {
  animation: fadeIn 0.3s ease-out;
}
