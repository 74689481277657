.profile-update-container {
  display: flex;
  font-family: var(--font-proxima-nova);
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.profile-update-header {
  text-align: left;
  margin-bottom: 20px;
  width: 100%;
  padding: 0 150px;
}

.profile-update-title {
  color: #2685ce;
  font-family: var(--font-proxima-nova);
  font-size: 38px;
  margin-bottom: 0;
  margin-top: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.profile-update-subtitle {
  margin: 0;
  color: #3f3f3f;
  font-family: var(--font-proxima-nova);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.please-update-div {
  display: flex;
  flex-direction: row;
  background: #a5ebff;
  color: #000;
  font-family: var(--font-proxima-nova);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding: 0 40px;
}

.profile-update-description {
  color: #000;
  display: flex;
  align-items: center;
  padding: 0 40px 0px 110px;
}

.profile-update-image-div {
  padding-right: 40px;
}

.profile-update-image1 {
  height: 100%;
  width: 560px;
}

.profile-update-form {
  padding: 20px 0;
  padding-bottom: 60px;
  padding-top: 40px;
  border-radius: 8px;
  width: 700px;
}

.form-section {
  margin-bottom: 50px;
  border-radius: 6px;
  border: 1px solid #30d5c8;
  padding: 20px 0;
}

.section-title {
  color: #2685ce;
  text-align: center;
  font-family: var(--font-proxima-nova);
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  margin: 0;
  position: relative;
  top: -10px;
  /* border-bottom: 2px solid #30D5C8; */
}
.section-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px; /* Adjust spacing between title and form */
  padding: 0 20px; /* Padding to ensure it doesn't touch the container edges */
  border-bottom: 1px solid #30d5c8;
}
.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.form-label {
  width: 200px;
  margin-right: 10px;
  text-align: right;
  color: #2685ce;
  font-family: var(--font-proxima-nova);
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.form-input,
.form-select {
  width: 440px; /* Full width for larger inputs */
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #f2fffe;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;
  border-radius: 6px;
  border: 1px solid #30d5c8;
}
.form-input {
  width: 420px;
}

.form-input.small-input {
  width: 200px;
}
.form-select.small-input {
  width: 220px; /* Smaller width for age and gender inputs */
}

.dropdown-arrow {
  background: url('/public/profile-update/dropdown-arrow.svg') no-repeat right
    10px center; /* Adjust the position */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #f2fffe;
}

.unit-label {
  margin-left: 10px;
  color: #000;
}

.required-field {
  color: red;
}

.form-checkbox {
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.checkbox-group {
  margin-top: 15px;
}

.form-buttons {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.cancel-button,
.save-button {
  border-radius: 6px;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cancel-button {
  background: #1876bf;
  margin-right: 10px;
}

.cancel-button:hover {
  background: #155a8a;
}

.cancel-button:active {
  background: #104a70;
}

.save-button {
  background: #129997;
}

.save-button:hover {
  background: #0f807e;
}

.save-button:active {
  background: #0b6867;
}

.submit-button {
  border-radius: 6px;
  background: #374982;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background: #2d3b6a;
}

.submit-button:active {
  background: #242e53;
}

.checkbox-label {
  width: 100%;
  text-align: left;
  color: #3f3f3f;
  font-family: 'Proxima Nova';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  padding-left: 200px;
}

.notification {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #129997;
  color: white;
  padding: 10px 20px;
  border-radius: 6px;
  font-family: var(--font-proxima-nova);
  font-size: 16px;
  font-weight: 600;
  z-index: 1000;
  transition: opacity 0.5s ease-in-out;
}
.notification {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.notification.success {
  background-color: #4caf50; /* Green */
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  text-align: center;
}

.notification.error {
  background-color: #f44336; /* Red */
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  text-align: center;
}

.disabled-button {
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.65;
}

.form-input[readonly] {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
}

.form-select[disabled] {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
}

.readonly-dropdown {
  background: #e9ecef !important;
  border: 1px solid #ced4da;
  appearance: none; /* Hide dropdown arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  pointer-events: none;
}
.edit-button {
  border-radius: 6px;
  background: #129997;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.edit-button:hover {
  background: #0f807e;
}

.edit-button:active {
  background: #0b6867;
}
.form-input[readonly],
.form-select[disabled] {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  color: #495057;
  box-shadow: none;
}

.form-input.small-input {
  width: 200px;
}
.form-select.small-input {
  width: 220px;
}

.form-group .form-input[readonly],
.form-group .form-select[disabled] {
  width: 440px; /* Ensure readonly inputs maintain the same width */
}

/* Apply consistent styling for all non-editable inputs and selects */
.form-input[readonly],
.form-select[disabled],
.readonly-dropdown {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  color: #495057;
  box-shadow: none;
  width: 440px; /* Ensures consistent width for all fields */
  padding: 10px;
  margin-top: 5px;
  border-radius: 4px;
}
/* Ensuring consistent width for form groups */
.form-group .form-input[readonly] {
  width: 420px;
}
.form-group .form-select[disabled] {
  width: 440px;
}

/* Consistent width for small inputs */
.form-input.small-input[readonly] {
  width: 200px;
}
.form-select.small-input[disabled] {
  width: 220px;
}

/* Styling for disabled selects (dropdowns) */
.readonly-dropdown[disabled] {
  background: #e9ecef !important;
  border: 1px solid #ced4da;
  color: #495057;
  box-shadow: none;
  pointer-events: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* Hide dropdown arrow for readonly dropdowns */
.readonly-dropdown {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  pointer-events: none;
  background: #e9ecef !important;
  border: 1px solid #ced4da;
  color: #495057;
}

.unable-to-load {
  padding: 150px;
}
.new-file {
  margin: 0;
}
.file-container {
  padding: 5px 10px;
  width: 420px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #f2fffe;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;
  border-radius: 6px;
  border: 1px solid #30d5c8;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.file-container.read-only {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  color: #495057;
  box-shadow: none;
  width: 420px;
  padding: 10px;
  margin-top: 5px;
  border-radius: 4px;
}
.file-link {
  text-decoration: none;
}

@media screen and (max-width: 1400px) {
  .profile-update-description {
    padding: 0 70px 0 110px;
  }
  .profile-update-image1 {
    width: 400px;
  }
}
@media screen and (max-width: 1200px) {
  .profile-update-description {
    padding: 20px;
    padding-left: 40px;
  }
  .profile-update-image-div {
    padding-right: 20px;
  }
  .profile-update-header {
    padding: 0 80px;
  }
}

@media screen and (max-width: 450px) {
  .profile-update-container {
    /* flex-direction: column; */
  }
  .unable-to-load {
    padding: 150px 20px;
  }
  .unable-to-load-text {
    text-align: center;
  }
  .profile-update-header {
    padding: 0 30px;
  }
  .profile-update-title {
    font-size: 26px;
  }
  .please-update-div {
    flex-direction: column-reverse;
    width: 100%;
    box-sizing: border-box;
    padding: 0 30px;
  }
  .profile-update-image-div {
    padding: 0;
    padding-top: 12px;
  }
  .profile-update-image1 {
    width: 100%;
  }
  .profile-update-description {
    padding: 0;
    padding-bottom: 12px;
  }
  .profile-update-form {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
  }
  .form-group {
    flex-direction: column;
    align-items: stretch;
    padding: 0 20px;
  }
  .form-label {
    text-align: left;
    /* padding-left: 12px; */
    box-sizing: border-box;
    width: 100%;
  }
  .form-input {
    width: 100%;
    /* margin-left: 12px;
    margin-right: 12px; */
    box-sizing: border-box;
  }
  .form-select {
    width: 100%;
    /* margin-left: 12px;
    margin-right: 12px; */
  }
  .file-container {
    gap: 10px;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    align-items: flex-start;
    padding: 10px;
  }
  .file-container.read-only {
    width: 100%;
    box-sizing: border-box;
  }
  .form-group .form-input[readonly] {
    width: 100%;
    box-sizing: border-box;
  }
  .form-input.small-input[readonly] {
    width: 200px;
  }
  .form-group .form-select[disabled] {
    width: 100%;
  }
  .form-select.small-input {
    width: 200px;
  }
  .unit-label {
    margin-left: 2px;
  }
  .section-title {
    font-size: 24px;
  }
  .checkbox-label {
    padding-left: 5px;
  }
}
