.thank-you-page-child,
.thank-you-page-item {
  position: relative;
  display: none;
  max-width: 100%;
}
.thank-you-page-child {
  width: 1200px;
  height: 68px;
  background-color: #d9d9d9;
}
.thank-you-page-item {
  width: 680px;
  height: 115px;
  border-radius: 8px;
  border: 2px solid #80d6e9;
  box-sizing: border-box;
}
.thankyou-content,
.thank-you-page {
  display: flex;
  align-items: flex-start;
}
.thankyou-content {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl) 23px;
  box-sizing: border-box;
  max-width: 100%;
}
.thank-you-page {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  gap: 98px;
  margin-top: 98px;
  margin-bottom: 30px;
  line-height: normal;
  letter-spacing: normal;
}

.thank-you-for {
  color: #2d94dd;
  font-family: var(--font-proxima-nova);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 100% */
}
.thank-you-for-subscribing {
  margin: 0;
}
.blank-line {
  margin: 0;
  font-size: var(--font-size-base);
}
.thank-you-for-subscribing-to-c {
  margin: 0;
  color: var(--color-dimgray);
}
.thank-you-for-container {
  flex: 1;
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: 100%;
}
.message {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-8xs);
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--font-size-5xl);
  color: var(--color-cornflowerblue-100);
}
.follow-us-on,
.stay-updated-with {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}
.follow-us-on {
  color: #000;
  font-family: var(--font-proxima-nova);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}
.updates-child {
  width: 494px;
  position: relative;
  max-height: 100%;
  max-width: 100%;
}
.social,
.updates {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.updates {
  gap: var(--gap-7xs);
  font-size: var(--font-size-base);
  color: var(--color-dimgray);
}
.social {
  gap: var(--gap-xs);
}
.help-us-grow {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}
.share-with-your {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  color: #000;
  font-family: var(--font-proxima-nova);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}
.help-us-grow {
  font-size: var(--font-size-base);
  color: var(--color-dimgray);
}
.share {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.refer-us {
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 22.4px;
  text-transform: uppercase;
  display: inline-block;
  font-family: var(--font-proxima-nova);
  color: var(--color-white);
  text-align: left;
  min-width: 84px;
}
.thankyou-search,
.spread {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.thankyou-search {
  cursor: pointer;
  /* border: 0; */
  padding: var(--padding-4xs) var(--padding-60xl);
  /* background-color: var(--color-darkslateblue); */
  /* border-radius: var(--br-9xs); */
  /* flex-direction: row; */
  /* white-space: nowrap; */
  border-radius: 4px;
  background: #00244e;
  display: inline-flex;
  padding: 9px 80px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.thankyou-search:hover {
  background-color: var(--color-steelblue-200);
}
.spread {
  align-self: stretch;
  flex-direction: column;
  padding: 0 0 var(--padding-xs);
  gap: var(--gap-6xl);
}
.what-happens-next {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  color: #000;
  font-family: var(--font-proxima-nova);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}
.check-thick-check-form-valida-icon,
.check-thick-check-form-valida-icon1,
.check-thick-check-form-valida-icon2 {
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px;
}
.checkmark,
.success {
  display: flex;
  /* flex-direction: column; */
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.success {
  gap: 6px;
}
.checkmark {
  padding: var(--padding-12xs) 0 0;
}
.payment-verification {
  font-family: var(--font-proxima-nova);
}
.payment-verification-we-will {
  margin: 0;
}
.welcome-email {
  font-family: var(--font-proxima-nova);
}
.welcome-email-you-will-receiv {
  margin: 0;
}
.access-benefits {
  font-family: var(--font-proxima-nova);
}
.access-benefits-start-enjoyin {
  margin: 0;
}
.payment-verification-we-container {
  flex: 1;
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 424px;
  max-width: 100%;
}
.next-steps,
.steps,
.verification {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.verification {
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap-5xs);
}
.next-steps,
.steps {
  align-self: stretch;
  box-sizing: border-box;
}
.steps {
  flex-direction: row;
  padding: 0 0 0 2px;
  font-size: var(--font-size-base);
  color: var(--color-dimgray);
}
.next-steps {
  flex-direction: column;
  padding: 0 0 var(--padding-8xs);
  gap: var(--gap-xs);
}
.need-assistance {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  color: #000;
  font-family: var(--font-proxima-nova);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}
.ty-icon {
  cursor: pointer;
}
.ty-vaidhyameghacom {
  text-decoration: none;
  color: var(--color-cornflowerblue-200);
}
.or-91-9618986039 {
  color: var(--color-dimgray);
  text-decoration: none;
}
.blank-line1,
.if-you-have-any-questions-or-n,
.thank-you-for1 {
  margin: 0;
}
.blank-line33 {
  margin: 0;
  line-height: 8px;
}
.if-you-have-container {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 24px;
  color: var(--color-dimgray);
}
.assistance,
.subscription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.assistance {
  align-self: stretch;
  gap: var(--gap-xs);
}
.subscription {
  width: 680px;
  gap: var(--gap-30xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-xl);
  color: black;
  font-family: var(--font-proxima-nova);
}
.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px; /* Adjust this value to set the desired spacing between icons and lines */
}

.social-icon {
  display: flex;
  align-items: center;
}

.line-divider {
  width: 1px;
  height: 24px;
  background: linear-gradient(90deg, #bee4ff 0%, #728999 100%);
}

@media screen and (max-width: 750px) {
  .payment-verification-we-container {
    min-width: 100%;
  }
  .subscription {
    gap: var(--gap-5xl);
  }
  .thank-you-page {
    gap: var(--gap-30xl);
  }
}
@media screen and (max-width: 450px) {
  .follow-us-on,
  .need-assistance,
  .share-with-your,
  .what-happens-next {
    font-size: var(--font-size-base);
    line-height: 19px;
  }
  .thank-you-page {
    gap: var(--gap-5xl);
  }
}
