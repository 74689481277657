/* Thumbnail for file preview */
.file-preview-thumbnail {
  max-width: 100px;
  max-height: 100px;
  margin-top: 10px;
  cursor: pointer;
  border: 1px solid #ccc;
}

.upload-button {
  padding: 8px 12px;
  color: white;
  background-color: #5656f2;
  border: 1px solid;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 15px;
}

/* Container for file in read-only mode */
.readonly-file-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Styling the download button */
.download-button {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  margin-left: 10px;
}
.download-button a {
  color: white;
  text-decoration: none;
}
.notification {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.notification.success {
  background-color: #4caf50; /* Green for success */
  color: white;
}

.notification.error {
  background-color: #f44336; /* Red for error */
  color: white;
}

@media screen and (max-width: 450px) {
  .upload-button {
    margin-left: auto;
  }
}
